import { css } from '@emotion/react'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/components/BodyTextBlock'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'li'> {
  data?:
    | Queries.AdmissionsEventFragment
    | Queries.AlumnaeEventFragment
    | null
  oddEven?: 'ODD' | 'EVEN'
}

export const CalendarEvent = ({
  data,
  oddEven,
  ...props
}: Props): JSX.Element => {
  const eventDate = data?.dateAndTime && new Date(data?.dateAndTime)
  const day = eventDate && format(eventDate, 'd')
  const getDayOfWeek = (date: Date) => {
    const dayOfWeek = format(date, 'EEEEEE')
    switch (dayOfWeek) {
      case 'Mo':
        return 'M'
      case 'We':
        return 'W'
      case 'Fr':
        return 'F'
      default:
        return dayOfWeek
    }
  }
  const getTime = (date: Date) => {
    const hour = format(date, 'h')
    const minute = format(date, 'mm')
    const ampm = format(date, 'aaa')
    if (minute !== '00') {
      return `${hour}:${minute}${ampm}`
    } else return `${hour}${ampm}`
  }

  const styles = {
    event: css`
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 0.5em;
      padding: 1.5em 0 1.67em;
      ${oddEven === 'ODD' &&
      css`
        background: ${colors.gray95};
      `}
      ${oddEven === 'EVEN' &&
      css`
        background: ${colors.gray98};
      `}
      ${mq().s} {
        grid-column-gap: 0;
      }
    `,
    date: css`
      display: grid;
      align-self: flex-start;
      align-content: center;
      justify-items: center;
      font-size: var(--fs-72);
      width: 2.5em;
      padding: 0 0.25em;
      box-sizing: border-box;
      ${mq().s} {
        width: 2em;
        font-size: var(--fs-48);
        padding-top: 0.075em;
      }
    `,
    day: css`
      display: block;
      font-family: var(--ff-display);
      line-height: 1;
      margin: 0;
    `,
    dayOfWeek: css`
      display: block;
      font-size: var(--fs-21);
      font-weight: 350;
      color: ${colors.gray65};
      ${mq().s} {
        font-size: var(--fs-16);
      }
    `,
    info: css`
      padding-right: 1.5em;
    `,
    title: css`
      font-family: var(--ff-display);
      font-size: var(--fs-48);
      font-weight: 350;
      margin: 0.125em 0 0.333em;
      ${mq().s} {
        font-family: var(--ff-body);
        font-size: var(--fs-24);
      }
    `,
    details: css`
      font-size: var(--fs-21);
      color: ${colors.gray45};
      display: block;
      line-height: 1.333;
      a {
        color: ${colors.red};
        text-underline-offset: 0.15em;
        text-decoration-thickness: 1px;
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
      > span,
      > a {
        display: inline-block;
      }
      ${mq().s} {
        font-size: var(--fs-18);
      }
    `,
    description: css`
      margin-top: 1.25em;
      ${mq().s} {
        font-size: var(--fs-15);
      }
    `,
  }
  return (
    <li
      css={styles.event}
      {...props}
    >
      <div css={styles.date}>
        <span css={styles.day}>{day}</span>
        <span css={styles.dayOfWeek}>
          {eventDate && getDayOfWeek(eventDate)}
        </span>
      </div>
      <div css={styles.info}>
        <h4 css={styles.title}>
          <span>{data?.eventName}</span>
        </h4>
        <div css={styles.details}>
          <span>{eventDate && getTime(eventDate)}</span>
          <span>&ensp;|&ensp;</span>
          {data?.locationMapUrl ? (
            <a
              href={data?.locationMapUrl}
              target="_blank"
              rel="noreferrer"
            >
              {data?.location}
            </a>
          ) : (
            <span>{data?.location || 'TBD'}</span>
          )}
        </div>
        <BodyTextBlock
          data={data?.description}
          css={styles.description}
        />
      </div>
    </li>
  )
}

export const CalendarEventFragments = graphql`
  fragment AdmissionsEvent on DatoCmsAdmissionsEvent {
    __typename
    id: originalId
    eventName
    dateAndTime
    location
    locationMapUrl
    division {
      id: originalId
      divisionName
    }
    description {
      ...BodyTextBlock
    }
  }
  fragment AlumnaeEvent on DatoCmsAlumnaeEvent {
    __typename
    id: originalId
    eventName
    eventType
    dateAndTime
    location
    locationMapUrl
    description {
      ...BodyTextBlock
    }
  }
`
